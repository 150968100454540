body {
  font-family: "Poppins", sans-serif !important;
  scrollbar-width: none;
  margin: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
}

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~react-loading-skeleton/dist/skeleton.css";

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

#root {
  min-height: 100vh;
}

.bp4-divider {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bp4-numeric-input > .bp4-input-group {
  width: 100% !important;
}

.bp4-toast-container {
  position: fixed !important;
  z-index: 100000000;
}

:focus {
  outline: none !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.gray-hover:hover {
  cursor: pointer;
  background: #edeff2 !important;
}

.gray-hover-2:hover {
  cursor: pointer;
  background: #dce0e5 !important;
}

.underline-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.brightness-hover:hover {
  filter: brightness(60%);
}

.bp4-overlay-content {
  z-index: 10001;
}

.bp4-tab-list {
  width: 100%;
}

.sketch-picker {
  box-shadow: none !important;
}

.bp4-popover2-target {
  height: fit-content;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.bp4-overlay {
  z-index: 100000000;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

#homepage-animation {
  margin: -12%;
}

.text-cycle-container {
  overflow: hidden;
}

.text-cycle-item {
  transition: transform 1s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(0);
  opacity: 1;
}

.animate-out {
  transform: translateY(100%);
  opacity: 0;
}
