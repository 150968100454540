.scroll {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.scroll-gradient-fade-bottom {
  height: 100px;
  width: 100%;
  bottom: 0;
  position: absolute;
  background: linear-gradient(0deg, rgb(45, 114, 210) 0%, rgba(0, 0, 0, 0) 100%);
}
.scroll-gradient-fade-left {
  height: 100%;
  width: 150px;
  left: 0;
  position: absolute;
  background: linear-gradient(270deg, rgb(45, 114, 210) 10%, rgba(0, 0, 0, 0) 100%);
  transform: rotate(-180deg);
}
.scroll-gradient-fade-right {
  height: 100%;
  width: 150px;
  right: 0;
  position: absolute;
  background: linear-gradient(270deg, rgb(45, 114, 210) 10%, rgba(0, 0, 0, 0) 100%);
}
.scroll-article {
  display: flex;
  flex-direction: column;
  padding: 10;
  animation: animate 120s -120s linear infinite;
  will-change: transform;
}
.scroll-article:nth-child(2) {
  animation: animate2 120s -60s linear infinite;
}
.fade-in {
  animation: fadeIn 1s;
}
@keyframes animate {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes animate2 {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.question-mark {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 3rem;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  50% {
    transform: translate(calc(100% - 3rem), calc(100% - 3rem)) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

.hover-zoom:hover {
  transform: scale(1.05);
  transition: transform 0.5s;
}
